.App {
  font-family: sans-serif;
  text-align: center;
  overflow: hidden;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.site-container {
  flex: 1;
}
footer {
  left: 0px;
  right: 0px;
  font-size: 11px;
  margin-top: 50px;
}
.phrase-container {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.button-wrapper {
}
button.generate:before {
  content: "";
  display: block;
  background: rgba(255, 255, 255, 0);
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
  left: 0px;
  right: 0px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
button.generate:hover:before {
  background: rgba(255, 255, 255, 0.1);
}

button.generate {
  background-color: #1a73e8;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  position: relative;
  top: 0px;
}
button.generate:active {
  top: 3px;
}
button.generate.mild {
  background-color: purple;
}
button.generate.moderate {
  background-color: orangered;
}
button.generate.severe {
  background-color: rgb(185, 0, 0);
}
button.generate.defcon1 {
  background-color: rgb(117, 0, 0);
  padding: 10px;
}
button.generate.defcon2 {
  background-color: rgb(0, 0, 0);
  padding: 20px;
}
button.generate.dead {
  background-color: gray;
}

/* */
.form-check {
  padding: 10px 12px;
  display: inline;
  border-radius: 7px;
  border: 1px solid #dedede;
}
.form-check-label {
  font-size: 12px;
  margin: 0px;
  padding: 0px;
}
input#speak {
  float: none;
  margin-right: 5px;
  margin-left: 0px;
}
/* */
.adsbygoogle {
  display: block;
  margin-top: 50px;
}

/* */

.slide-in-elliptic-top-fwd {
  -webkit-animation: slide-in-elliptic-top-fwd 1s
    cubic-bezier(0.68, -0.55, 0.265, 1.55) both;
  animation: slide-in-elliptic-top-fwd 1s cubic-bezier(0.68, -0.55, 0.265, 1.55)
    both;
}
.fade-in {
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
/**
 * ----------------------------------------
 * animation slide-in-elliptic-top-fwd
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-elliptic-top-fwd {
  0% {
    -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
    transform: translateY(-600px) rotateX(-30deg) scale(0);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) rotateX(0) scale(1);
    transform: translateY(0) rotateX(0) scale(1);
    -webkit-transform-origin: 50% 1400px;
    transform-origin: 50% 1400px;
    opacity: 1;
  }
}
@keyframes slide-in-elliptic-top-fwd {
  0% {
    -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
    transform: translateY(-600px) rotateX(-30deg) scale(0);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) rotateX(0) scale(1);
    transform: translateY(0) rotateX(0) scale(1);
    -webkit-transform-origin: 50% 1400px;
    transform-origin: 50% 1400px;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
